import * as React from "react";
import { External } from "../../components/Link";
import { useHydrateAtoms } from 'jotai/utils'
import { heroAtom, seoAtom } from "@/store";
import { Layout } from "@components/Layout";
import { Container } from "@components/Layout/Container";
import { Header } from "@components/Header";
import { Title } from "@components/Header/Title";
import { BreadCrumbs } from "@components/Breadcrumbs";
import { graphql } from "gatsby";
import { Helmet } from "react-helmet";
import { EmailListForm } from "@components/EmailListForm";

type ContactUsPageProps = {
  data: {
    hero: any;
  };
};

const ContactUsPage = ({ data }: ContactUsPageProps) => {
  const seo = { title: "Contact Us", description: "Contact the EP3 team." };
  useHydrateAtoms([
    [heroAtom, data?.hero],
    [seoAtom, seo],
  ])
  return (
    <>
      <Helmet>
        <title> Contact Us | EP3 Guide</title>
      </Helmet>
      <Layout>
        <Container>
          <Header>
            <BreadCrumbs
              paths={[
                { url: "/", label: "Home" },
                { url: "/about", label: "About" },
              ]}
              title={"Contact Us"}
            />
            <Title>{"Contact Us"}</Title>
          </Header>
          <p className="p-8 text-xl text-center rounded-lg bg-sky-100 md:text-left">
            Contact us at{" "}
            <External href="mailto:ep3@aps.org" className="block font-bold text-quaternary md:inline">
              ep3@aps.org
            </External>{" "}
            with suggestions, comments, or questions.
          </p>

          <div className="grid items-center justify-center w-full p-5 mt-4">
            <EmailListForm />
          </div>
        </Container>
      </Layout>
    </>
  );
};

export default ContactUsPage;

export const pageQuery = graphql`
  query ContactUsPage {
    hero: file(relativePath: { eq: "hero-random-page.jpg" }) {
      ...heroImageFragment
    }
  }
`;

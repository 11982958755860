import * as React from "react";
import { paramCase } from "change-case";
export const BreadCrumbs: React.FC<{
  title: string;
  paths: { url: string; label: string }[];
}> = ({ title, paths }) => (
  <ol className="breadcrumbs flex flex-wrap gap-x-3 font-display text-sm md:text-base md:text-lg font-medium">
    {paths.map((path) => (
      <li key={paramCase(path.label)}>
        <a href={path.url} className="text-primary hover:underline whitespace-nowrap">
          {path.label}
        </a>
      </li>
    ))}
    <li>{title}</li>
  </ol>
);

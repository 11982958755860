import React from "react";
import { fromEvent } from 'rxjs';
import { debounceTime } from 'rxjs/operators';


// Check if `child` is a descendant of `parent`
const isDescendant = function (parent: number | HTMLElement, child: HTMLElement) {
  let node = child.parentNode;
  while (node) {
    if (node === parent) {
      return true;
    }

    // Traverse up to the parent
    node = node.parentNode;
  }

  // Go up until the root but couldn't find the `parent`
  return false;
};

const useUpdateOnScroll = () => {
  React.useEffect(() => {
    const $scroll = fromEvent(document, 'scroll').pipe(
      debounceTime(50)
    )
    const subscription = $scroll.subscribe();
    return () => subscription.unsubscribe();
  }, [])
}

const useNavigationButtonFix = () => {
  React.useEffect(() => {

    const PopStateFn = (ev: { preventDefault: () => void; state: null; }) => {
      ev.preventDefault();
      if (ev.state == null) {
        return;
      }
      // ev.currentTarget.history.go(-1);
    }

    window.addEventListener('popstate', PopStateFn);
    return () => window.removeEventListener('popstate', PopStateFn);
  }, [])
}

export const Container: React.FC<React.PropsWithChildren<{}>> = ({ children }) => {
  useUpdateOnScroll();
  useNavigationButtonFix();
  return (
    <div className="flex justify-center relative">
      <div className="w-full max-w-screen md:max-w-screen-2xl transition-all duration-200 ease-in-out relative z-10 border-t-8 border-primary my-auto">
        <div className="w-full bg-white absolute h-[22rem] md:h-[23rem] lg:h-[23rem]"></div>

        <div className="absolute mt-[21rem] md:mt-[19.5rem] lg:mt-[18.5rem]  w-full h-[100px] bg-white"></div>
        <div className="w-full relative pb-6 lg:pb-12 mx-auto px-4 md:px-8 2xl:px-16 pt-4 md:pt-8 min-h-[25rem]">
          {children}
        </div>
      </div>
    </div>
  )
};

export const WideContainer: React.FC<React.PropsWithChildren<{}>> = ({ children }) => (
  <div className="max-w-screen-2xl transition-all duration-200 ease-in-out relative z-10 bg-white m-auto">
    {children}
  </div>
);

